// 79909
$(document).ready(function () {
    $(".goto-rfi").click(function () {
        if ($(".floting-rfi")[0]) {
            $('html,body').animate({
                scrollTop: $(".floting-rfi").offset().top - 140 }, 'slow');
        } else {
            $('html,body').animate({
                scrollTop: $("#rfi").offset().top - 140 }, 'slow');
        }
    });
    // 69502
    $(".goto-rfi-home").click(function () {
        $('html,body').animate({
            scrollTop: $("#rfi").offset().top - 100 },
            'slow');
    });
});
